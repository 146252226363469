<template>
  <div
    :style="getElementStyle"
    v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
  >

  <div v-if="!isDefalutPos" style="display: flex; align-items: center;">
    <div style="white-space: nowrap;">
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
      <label
          for="text"
          v-if="hasLabel && !data.properties.hideLabel"
          :style="getLabelStyles"
        >
          {{ label||data.label}}
      </label>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
        >*</span>
        <span style="margin-right: 5px;">
          <el-popover
            v-if="data.description"
            placement="top-start"
            title="Help"
            trigger="click"
            :content="data.description"
          >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
          </el-popover>
        </span>
    </div>
    <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24" style="display: contents;">
        <el-time-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          is-range
          range-separator="To"
          :start-placeholder="data.placeholder || data.label"
          prefix-icon="el-icon-time"
          class="eb-ctm-input-time"
          end-placeholder="End time"
          :style="getStyle"
           :format="getGlobalTimeFormat('TIME')"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          @change="applyFormRules"
          v-model="form[data.key]"
        >
        </el-time-picker>
        <el-time-picker
          v-if="!isList && !isView && data.properties.filed_content != 'Hide'"
          is-range
          range-separator="To"
          :start-placeholder="data.placeholder || 'Start time'"
          prefix-icon="el-icon-time"
          class="eb-ctm-input-time"
          :end-placeholder="data.placeholder1|| 'End time'"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
           :format="getGlobalTimeFormat('TIME')"
      
          v-model="form[data.key]"
          @change="updateTimeDate"
        >
        </el-time-picker>

        <!-- <el-date-picker
        v-if="isList"
          prefix-icon="el-icon-date"
          v-model="form[this.data.key]" 
          :readonly="true"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker>-->

        <!-- <el-date-picker
        v-if="!isView  && !isList"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker>-->

        <!-- <p v-if="isView" :style="getStyle">
            <template v-if="form[data.key]">{{
              form[data.key] | moment("YYYY-DD-MM HH:mm")
            }}</template>
            <template v-else>-</template>
          </p>
        -->
      </el-col>
  </div>
    <!-- {{ data }} -->
    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        v-if="!data.properties.hideLabel"
        :style="computedStyles"
        class="bottom-space"
      >
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label
          for="text"
          v-if="hasLabel && !data.properties.hideLabel"
          :style="getLabelStyles"
        >
          {{label||data.label }}
        </label>
        <span
        v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold; margin-left: 5px; margin-right: 5px;"
        >*</span>
        <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
            </el-popover>
          </span>
      </el-col>
      <el-col v-else :span="showLabel ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <span v-if="hasLabel" style="color: white; display: inline-block; height: 20px; opacity: 0;" ></span>
      </el-col>
      <!-- <el-col :span="data.description ? 4 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          title="Help"
          trigger="click"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
      </el-col> -->
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <el-time-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          is-range
          range-separator="To"
          :start-placeholder="data.placeholder || data.label"
          prefix-icon="el-icon-time"
          class="eb-ctm-input-time"
          end-placeholder="End time"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
          :picker-options="{
            format: getGlobalTimeFormat(),
          }"
          @change="applyFormRules"
          v-model="form[data.key]"
        >
        </el-time-picker>
        <el-time-picker
          v-if="!isList && !isView && data.properties.filed_content != 'Hide'"
          is-range
          range-separator="To"
          :start-placeholder="data.placeholder || 'Start time'"
          prefix-icon="el-icon-time"
          class="eb-ctm-input-time"
          :end-placeholder="data.placeholder1|| 'End time'"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :readonly="readonly()"
           :format="getGlobalTimeFormat('TIME')"
      
          v-model="form[data.key]"
          @change="updateTimeDate"
          :class="{ 'highlighted-border': highlight }"
        >
        </el-time-picker>

        <!-- <el-date-picker
        v-if="isList"
          prefix-icon="el-icon-date"
          v-model="form[this.data.key]" 
          :readonly="true"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker>-->

        <!-- <el-date-picker
        v-if="!isView  && !isList"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker>-->

        <!-- <p v-if="isView" :style="getStyle">
            <template v-if="form[data.key]">{{
              form[data.key] | moment("YYYY-DD-MM HH:mm")
            }}</template>
            <template v-else>-</template>
          </p>
        -->
      </el-col>
    </el-row>
    <!--<div class="form-group">
      <label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
      <el-popover
        v-if="data.description"
        placement="top-start"
        title="Help"
        trigger="click"
        :content="data.description"
      >
        <i class="el-icon-info" slot="reference"></i>
      </el-popover>

      <el-date-picker
        v-if="!isView"
        prefix-icon="el-icon-date"
        v-model="form[data.key]"
        type="date"
          :placeholder="data.placeholder || data.label" 
        :style="getStyle"
        :disabled="checkReadonly()"
      ></el-date-picker>

     
    </div>-->
    <p
      v-if="isView && data.properties.filed_content != 'Hide'"
      :style="getStyle"
    >
      <template v-if="form[data.key]">{{
        getGlobalFormattedTime(form[data.key][0], true) | moment("HH:mm:ss")
      }}</template>
      -
      <template v-if="form[data.key]">{{
        getGlobalFormattedTime(form[data.key][1], true) | moment("HH:mm:ss")
      }}</template>
      <template v-else>-</template>
    </p>
  </div>
  <div v-else>
    <div v-if="isView">
      <template v-if="form[data.key]">{{
        getGlobalFormattedTime(form[data.key][0], true) | moment("HH:mm:ss")
      }}</template>
      -
      <template v-if="form[data.key]">{{
        getGlobalFormattedTime(form[data.key][1], true) | moment("HH:mm:ss")
      }}</template>
      <template v-else>-</template>
    </div>
    <div v-else>
      <el-time-picker
        v-if="!isView && data.properties.filed_content != 'Hide'"
        prefix-icon="el-icon-time"
        v-model="form[data.key]"
        :placeholder="data.placeholder || data.label"
        :style="getStyle"
        :picker-options="{
          format: getGlobalTimeFormat(),
        }"
        :disabled="isFromDocument ? false : checkReadonly()"
        :readonly="readonly()"
        class="eb-ctm-input-time"
    
        is-range
        @change="updateTimeDate"
      ></el-time-picker>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import FieldsFormatHelper from "../../../mixins/FieldsFormatHelper";

import { mapGetters } from "vuex";
import moment from "moment";
import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
  name: "templates-formComponentsExecute-DateExecute",
  mixins: [userPermissionsHelper, FieldsFormatHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "profilePage",
    "checkIsDisabled",
    "checkhideFields",
    "colorFields",
    "highlight",
    "isIconRequired"
    
  ],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    getStyle() {
      return `height:${this.data.height - 35}px; width: 100%`;
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    getTimeProperties() {
      if (
        this.data?.properties?.time_range &&
        this.data?.properties?.step_time
      ) {
        let properties = {};
        properties["start"] = moment(
          this.data?.properties?.time_range[0]
        ).format("HH:mm");
        properties["end"] = moment(this.data?.properties?.time_range[1]).format(
          "HH:mm"
        );
        if (
          this.data?.properties?.step_time == 60 ||
          this.data?.properties?.step_time == "60"
        ) {
          properties["step"] = "01:00";
        } else {
          properties["step"] = "00:" + this.data?.properties?.step_time;
        }
        return properties;
      }
      return {
        start: "00:00",
        step: "00:30",
        end: "23:59"
      };
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = 'flex';

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  data() {
    return {
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false
    };
  },
  mounted() {
    this.loading = true;
    //this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    // this.updateTimeDate();
    this.loading = false;
  },
  methods: {
    defaultMethod(){
      if (this.data.default_value && this.form &&this.data.key && !this.form[this.data.key]) {
      this.$set(this.form, this.data.key, this.data.default_value);
    }
    this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    updateTimeDate() {
      this.applyFormRules()
      let start = "00:00:00";
      let end = "23:59:59";
      if (this.form[this.data.key] && this.form[this.data.key].length) {
        start = moment(this.form[this.data.key][0]).format("HH:mm:ss");
        end = moment(this.form[this.data.key][1]).format("HH:mm:ss");
      }
      this.$set(this.form, this.data.key + "_timestart", start);
      this.$set(this.form, this.data.key + "_timeend", end);
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (globalVariable.input_type == "TIME_RANGE") {
          this.isList = true;
          this.form[this.data.key] = globalVariable.value;
        }
      }
    },

    readonly() {
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_global_variable
      ) {
        return true;
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    }
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

  <style lang="scss" scoped>
  .highlighted-border{
    border-color: red !important;
  }
</style>
